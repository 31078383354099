import { render, staticRenderFns } from "./CompanyInformation.vue?vue&type=template&id=de5a0e26"
import script from "./CompanyInformation.vue?vue&type=script&lang=js"
export * from "./CompanyInformation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/test.webapp/releases/release_2025-04-01-123926/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('de5a0e26')) {
      api.createRecord('de5a0e26', component.options)
    } else {
      api.reload('de5a0e26', component.options)
    }
    module.hot.accept("./CompanyInformation.vue?vue&type=template&id=de5a0e26", function () {
      api.rerender('de5a0e26', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/SetUp/Company/CompanyInformation.vue"
export default component.exports