import { render, staticRenderFns } from "./LanguageSwitcher.vue?vue&type=template&id=21edb0c7"
import script from "./LanguageSwitcher.vue?vue&type=script&lang=js"
export * from "./LanguageSwitcher.vue?vue&type=script&lang=js"
import style0 from "./LanguageSwitcher.vue?vue&type=style&index=0&id=21edb0c7&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/test.webapp/releases/release_2025-04-01-123926/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21edb0c7')) {
      api.createRecord('21edb0c7', component.options)
    } else {
      api.reload('21edb0c7', component.options)
    }
    module.hot.accept("./LanguageSwitcher.vue?vue&type=template&id=21edb0c7", function () {
      api.rerender('21edb0c7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/common/components/LanguageSwitcher.vue"
export default component.exports