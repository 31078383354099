var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper w-100 full-height" },
    [
      _vm.isNavExists ? _c("SideNav") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-column w-100" },
        [
          _vm.showMobileDownloadNotification
            ? _c("MobileDownloadNotification", {
                on: { close: _vm.closeNotification },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("AppHeader", {
            ref: "app-header",
            staticClass: "app-header",
            class: {
              "d-none": !_vm.$store.getters["global/showHeader"],
            },
          }),
          _vm._v(" "),
          _vm.shouldShowSecondaryHeader
            ? _c(_vm.getSecondaryHeader, {
                tag: "component",
                class: {
                  "haccp-printlayout-navbar": !_vm.isSharedAccess,
                  "menu-closed": !_vm.sideNavOpen,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-inline-flex full-height",
              staticStyle: { "flex-grow": "1" },
            },
            [
              !_vm.$route.name.includes("plan.setup")
                ? _c("PortalTarget", {
                    staticClass: "d-none d-lg-block",
                    class: { "shared-access-view": _vm.isSharedAccess },
                    attrs: { name: "sidemenu" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "shared-access-view": _vm.isSharedAccess,
                    "extended-header": _vm.showBillingDaysLeftNotification,
                  },
                  attrs: { id: "app" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "app-content overflow-clip",
                      class: {
                        "app-content-printlayout-haccp":
                          _vm.$route.name.includes("plan") &&
                          !_vm.isSharedAccess,
                        "d-none": _vm.hideContent,
                      },
                    },
                    [
                      _c("PortalTarget", {
                        staticClass: "d-lg-none",
                        attrs: { name: "sidemenu" },
                      }),
                      _vm._v(" "),
                      _vm.$store.getters.isCompaniesInited
                        ? _c("RouterView", {
                            staticStyle: { "flex-grow": "1" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("PortalTarget", {
                    staticClass: "app-panel",
                    attrs: { name: "panel" },
                    on: { change: _vm.handlePortalUpdate },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }