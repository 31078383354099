import { render, staticRenderFns } from "./BaseFilePreview.vue?vue&type=template&id=08bf6009&scoped=true"
import script from "./BaseFilePreview.vue?vue&type=script&lang=js"
export * from "./BaseFilePreview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08bf6009",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/test.webapp/releases/release_2025-04-01-123926/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('08bf6009')) {
      api.createRecord('08bf6009', component.options)
    } else {
      api.reload('08bf6009', component.options)
    }
    module.hot.accept("./BaseFilePreview.vue?vue&type=template&id=08bf6009&scoped=true", function () {
      api.rerender('08bf6009', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/common/components/BaseFilePreview/BaseFilePreview.vue"
export default component.exports